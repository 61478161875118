import { InjectionToken } from '@angular/core';
import { Directive, ElementRef, OnInit, Inject } from '@angular/core';
import { Destructor } from '@app/shared/destructor';
import { observeOnMutation } from '@app/utils';
import { takeUntil, debounceTime } from 'rxjs/operators';

export const WINDOW = new InjectionToken<Window>('Window', {
  providedIn: 'root',
  factory: () => window,
});

@Directive({
  selector: '[a11yPatcher]'
})
export class A11yPatcher extends Destructor implements OnInit {
  el: HTMLElement;

  constructor(private self: ElementRef, @Inject(WINDOW) private window: Window) {
    super();
  }

  ngOnInit(): void {
    this.el = this.self.nativeElement;
    observeOnMutation(this.el, { childList: true, subtree: true })
      .pipe(
        takeUntil(this.destruction),
        debounceTime(100)
      )
      .subscribe(() => this.action());
  }

  private action(): void {
    this.handleStickyBarLinks();
    this.handleFormElements();
  }

  //Empty link issue : link with no text for alternativeRoutes
  private handleStickyBarLinks(): void {
    const stickyBars = this.el.getElementsByTagName('w-sticky-bar');
    for (let i = 0; i < stickyBars.length; i++) {
      const links = stickyBars[i].getElementsByTagName('a');
      for (let j = 0; j < links.length; j++) {
        if (!links[j].textContent?.trim()) {
          links[j].textContent = '\u200B';
        }
      }
    }
  }

  // Missing form label
  private handleFormElements(): void {
    const inputSearches = this.el.getElementsByTagName('w-input-search');
    const nestedCheckboxes = this.el.getElementsByTagName('w-nested-checkbox');
    
    this.processElements(inputSearches);
    this.processElements(nestedCheckboxes);
  }

  private processElements(elements: HTMLCollectionOf<Element>): void {
    for (let i = 0; i < elements.length; i++) {
      const el = elements[i] as HTMLElement;
      const label = el.querySelector('label');
      const input = el.querySelector('input, select, textarea') as HTMLElement;

      if (label && !label.textContent?.trim()) {
        label.remove();
      }

      if (input) {
        if (!input.id) {
          input.id = 'input-id-' + Math.random().toString(36).substr(2, 9);
        }
        if (!input.hasAttribute('aria-labelledby')) {
          input.setAttribute('aria-labelledby', input.id);
        }
      }
    }
  }
}
